import React from "react"
import { Link } from "gatsby"
import { useWindowSize } from "react-use"
import { ToastContainer } from "react-toastify"

import Layout from "../components/layout.js"
import Seo from "../components/seo.js"

import "react-toastify/dist/ReactToastify.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-notifications-component/dist/theme.css"
import "animate.css"

import { WelcomeLayout } from "../styles/common.js"
import { useInitialFocus } from "../hooks/index.js"

import Logo from "../lib/logo.js"
import { TaliHappy } from "../lib/icons/Tali.js"
import useSSRStorage from "../hooks/useSSRStorage.js"

const WelcomeIndexPage = () => {
  const focusRef = useInitialFocus()
  const { height } = useWindowSize()
  const [teacherJwt] = useSSRStorage("jwt_teacher")
  const [studentJwt] = useSSRStorage("jwt_student")

  return (
    <Layout layoutHeight={height}>
      <ToastContainer />
      <Seo title="Welcome to Talidu" />
      <WelcomeLayout>
        <TaliHappy width={240} height={240} />
        <Logo />
        <p className="slogan"> Let`s learn to read and write!</p>
        <p className="question">I am</p>
        <nav>
          <Link
            ref={focusRef}
            className="button"
            to={studentJwt ? "/student/profile" : "/student"}
          >
            a student
          </Link>
          <Link
            style={{ margin: "1rem" }}
            className="button"
            to={teacherJwt ? "/teacher/profile" : "/teacher"}
          >
            a teacher
          </Link>
          {/*<Link*/}
          {/*  className="button"*/}
          {/*  to="/parent"*/}
          {/*>*/}
          {/*  Eltern*/}
          {/*</Link>*/}
        </nav>
      </WelcomeLayout>
    </Layout>
  )
}

export default WelcomeIndexPage
