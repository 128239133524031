import React from "react"
import styled from "styled-components"

const StyledTaliduLogo = styled.svg`
  max-width: 540px;
  max-height: 150px;
`

function TaliduLogo(props) {
  return (
    <StyledTaliduLogo
      className="talidu-logo"
      viewBox="0 0 540 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M512.463 56.291c0-3.074-1.085-6.15-3.434-8.32-4.699-4.702-12.109-4.702-16.628 0a11.789 11.789 0 00-3.434 8.32v42.142c0 20.98-31.628 26.045-38.134 5.426-.543-1.628-.904-3.617-.904-5.426V55.206c-.542-6.15-5.603-10.852-11.748-10.852a11.728 11.728 0 00-11.747 11.756v42.504c0 32.556 35.785 52.632 64.341 36.716 1.988 3.436 5.783 5.787 10.121 5.787a11.728 11.728 0 0011.747-11.756l-.18-73.07z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M190.938 48.333c-4.699-4.702-12.109-4.702-16.628 0-1.626 1.628-2.711 3.437-3.072 5.607-1.084-.723-2.169-1.628-3.253-2.351-7.591-4.883-16.266-7.416-25.303-7.416-16.447 0-31.086 8.501-39.761 21.523-21.146 32.014 1.265 75.783 39.761 75.783 9.398 0 18.616-2.894 26.387-8.139.723-.542 1.627-1.085 2.35-1.628.542 1.809 1.446 3.618 2.891 5.065 4.699 4.702 12.11 4.702 16.628 0a11.79 11.79 0 003.434-8.32V56.834c.181-3.075-1.085-6.15-3.434-8.5zm-48.437 69.634c-13.374 0-24.218-11.214-24.218-25.14 0-24.78 30.905-34.365 44.099-14.108 11.025 16.64-.542 39.248-19.881 39.248z"
        fill="#5BD1EC"
      />
      <path
        d="M85.467 127.552a11.728 11.728 0 00-11.747-11.756c-.543 0-1.085 0-1.808.181h-.18c-9.218 1.266-15.182-.362-15.182-9.405V19.756A11.729 11.729 0 0044.802 8a11.729 11.729 0 00-11.747 11.756v86.273c0 21.885 15.181 37.439 42.291 33.46 5.784-1.085 10.121-6.149 10.121-11.937z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        d="M297.013 48.71c-4.699-4.702-12.109-4.702-16.627 0-2.35 2.352-3.434 5.246-3.434 8.32v71.442a11.729 11.729 0 0011.747 11.757 11.73 11.73 0 0011.748-11.757V57.03c0-3.074-1.084-6.15-3.434-8.32z"
        fill="url(#prefix__paint2_linear)"
      />
      <path
        d="M410.529 21.384c0-3.075-1.085-5.969-3.434-8.32-4.699-4.702-12.109-4.702-16.627 0-2.35 2.351-3.434 5.245-3.434 8.32V51.59c-7.591-4.884-16.266-7.416-25.303-7.416-16.447 0-31.086 8.5-39.761 21.523-21.146 32.013 1.265 75.783 39.761 75.783 9.398 0 18.615-2.894 26.387-8.139 1.807 4.16 5.964 6.873 10.844 6.873a11.729 11.729 0 0011.748-11.756V21.384h-.181zm-48.979 96.402c-13.374 0-24.218-11.214-24.218-25.14 0-24.78 30.905-34.365 44.099-14.108 11.025 16.82-.542 39.248-19.881 39.248z"
        fill="url(#prefix__paint3_linear)"
      />
      <path
        d="M263.486 128.506a11.728 11.728 0 00-11.747-11.757c-.543 0-1.085 0-1.627.181-6.326.904-10.844.181-13.013-1.809-2.169-1.989-2.349-5.787-2.349-7.053V20.71a11.73 11.73 0 00-11.748-11.757 11.73 11.73 0 00-11.748 11.757v85.73c-.18 3.436-.18 16.278 9.399 25.502 6.144 5.969 14.277 8.862 24.579 8.862 2.531 0 5.242-.18 8.133-.542 5.603-.724 10.121-5.788 10.121-11.756z"
        fill="url(#prefix__paint4_linear)"
      />
      <path
        d="M83.04 47.429c-.18-.181-.18-.362-.361-.362-.181-.18-.181-.362-.362-.362-2.169-2.893-5.602-4.883-9.579-4.883H25.747A11.729 11.729 0 0014 53.578a11.729 11.729 0 0011.748 11.757H72.015c3.254.361 6.688-.905 9.398-3.437 3.796-3.798 4.338-9.767 1.627-14.47z"
        fill="#5BD2EC"
      />
      <path
        d="M183.889 128.456a11.728 11.728 0 0111.748-11.756 11.728 11.728 0 0111.747 11.756 11.728 11.728 0 01-11.747 11.757c-6.507.181-11.748-5.064-11.748-11.757z"
        fill="url(#prefix__paint5_linear)"
      />
      <path
        d="M277.433 20.71a11.728 11.728 0 0111.748-11.757 11.728 11.728 0 0111.747 11.757 11.728 11.728 0 01-11.747 11.756c-6.507.18-11.748-5.064-11.748-11.756z"
        fill="url(#prefix__paint6_linear)"
      />
      <path
        d="M401.491 128.122a11.728 11.728 0 0111.748-11.756 11.729 11.729 0 0111.748 11.756 11.729 11.729 0 01-11.748 11.756c-6.507.181-11.748-5.064-11.748-11.756z"
        fill="url(#prefix__paint7_linear)"
      />
      <path
        d="M502.986 128.499a11.729 11.729 0 0111.748-11.756 11.729 11.729 0 0111.748 11.756 11.73 11.73 0 01-11.748 11.757c-6.506.181-11.748-5.065-11.748-11.757z"
        fill="url(#prefix__paint8_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={426.258}
          y1={92.69}
          x2={476.558}
          y2={113.327}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59F26" />
          <stop offset={1} stopColor="#E15814" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={33.055}
          y1={21.825}
          x2={100.299}
          y2={150.019}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BD1EC" />
          <stop offset={1} stopColor="#5BD1EC" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={285.05}
          y1={76.17}
          x2={308.582}
          y2={150.638}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BD1EC" />
          <stop offset={1} stopColor="#05639E" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={313.687}
          y1={75.518}
          x2={442.756}
          y2={132.352}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59F26" />
          <stop offset={1} stopColor="#E15814" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear"
          x1={223.846}
          y1={74.739}
          x2={263.852}
          y2={161.631}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BD1EC" />
          <stop offset={1} stopColor="#05639E" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear"
          x1={187.182}
          y1={121.274}
          x2={204.172}
          y2={136.742}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59F26" />
          <stop offset={1} stopColor="#E15814" />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear"
          x1={280.726}
          y1={13.527}
          x2={297.716}
          y2={28.995}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59F26" />
          <stop offset={1} stopColor="#E15814" />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear"
          x1={404.784}
          y1={120.94}
          x2={421.774}
          y2={136.408}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59F26" />
          <stop offset={1} stopColor="#E15814" />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear"
          x1={506.28}
          y1={121.317}
          x2={523.27}
          y2={136.785}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59F26" />
          <stop offset={1} stopColor="#E15814" />
        </linearGradient>
      </defs>
    </StyledTaliduLogo>
  )
}

export default TaliduLogo
